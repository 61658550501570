// import { useState, useEffect } from "react";
import * as React from "react";
import { useEffect, useState } from "react";

import { Cog6ToothIcon, MoonIcon, SunIcon } from "@heroicons/react/24/solid";

const ThemeSwitch = () => {
  //   const [mounted, setMounted] = useState(false);
  //   const { resolvedTheme, setTheme } = useTheme();
  // useEffect only runs on the client, so now we can safely show the UI

  const [theme, saveTheme] = useState('system');

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');

    if (savedTheme) {
      // applyTheme(savedTheme);
      saveTheme(savedTheme);
      return;
    }

    onThemeChange(theme);
  }, []);

  function applyTheme(theme) {
    switch (theme) {
      case 'light':
        document.documentElement.classList.remove('dark');
        break;
      case 'dark':
        document.documentElement.classList.add('dark');
        break;
      case 'system':
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          document.documentElement.classList.add('dark');
        }
        break;
      default:
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          document.documentElement.classList.add('dark');
        }
    }
  }

  function onThemeChange(theme) {
    localStorage.setItem('theme', theme, { path: '/' });
    applyTheme(theme);
    saveTheme(theme);
  }

  return (
    <div className="inline-flex items-center mb-4 md:mb-0">
      {theme === 'system' && <Cog6ToothIcon className="w-6 h-6 mr-2 text-gray-400" />}
      {theme === 'dark' && <MoonIcon className="w-6 h-6 mr-2 text-blue-500" />}
      {theme === 'light' && <SunIcon className="w-6 h-6 mr-2 text-orange-500" />}
      <select
        name="themeSwitch"
        value={theme}
        className="bg-gray-200 text-sm rounded-lg w-full p-2.5 block border-r-8 border-gray-200 dark:bg-gray-800 dark:border-gray-800"
        onChange={e => onThemeChange(e.target.value)}>
        <option value="system">System</option>
        <option value="dark">Dark</option>
        <option value="light">Light</option>
      </select>
    </div>
  );
};

export default ThemeSwitch;
