import { Link } from "gatsby"
import * as React from "react"
import CookieConsent from "react-cookie-consent"
import Footer from "./footer"
import Navbar from "./navbar"

const Layout = props => {
  const { children } = props

  return (
    <div className="antialiased text-gray-800 dark:bg-gray-900 dark:text-stone-400 flex flex-col min-h-full">
      <Navbar {...props} className="flex-none" />
      <div className="grow">{children}</div>

      <Footer {...props} className="flex-none" />

      <CookieConsent
        location="bottom"
        buttonText="I understand"
        declineButtonText="Decline"
        cookieName="google-tagmanager"
        // enableDeclineButton
        // style={{ background: "#2B373B" }}
        // buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        buttonClasses="text-sm rounded-md !m-0 !ml-3 !my-4"
        contentClasses="text-sm !m-0"
        containerClasses="px-8 py-5 !bg-[#EEEEF8] dark:!bg-neutral-900 border-t border-t-neutral-400 dark:border-t-neutral-700 !text-slate-600 dark:!text-stone-400"
        overlayClasses="bg-red-500"
        declineButtonClasses="text-sm !bg-neutral-200 dark:!bg-neutral-700 !text-stone-600 dark:!text-stone-300 !m-0 rounded-md"
        expires={150}
      >
        This website uses cookies to enhance the user experience. Read our{" "}
        <Link to="/cookie-policy" className="font-bold underline">
          cookie policy
        </Link>
        .
      </CookieConsent>
    </div>
  )
}

export default Layout
