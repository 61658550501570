/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { useLocation } from "@gatsbyjs/reach-router"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import * as React from "react"
import { Helmet } from "react-helmet"

const Seo = ({ description, lang, meta, title, image, publishDate, tags }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            social {
              twitter
            }
            author {
              name
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const metaImage = image || '/social.jpg';
  const author = site.siteMetadata.author.name;
  const date = publishDate || '2021-01-01';
  const keywords = tags

  const location = useLocation();

  const basicTags = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      name: `author`,
      content: author,
    },
    {
      name: `keywords`,
      content: keywords,
    },
    {
      name: `article:published_time`,
      content: date,
    },
  ];

  const ogTags = [
    {
      property: `og:title`,
      content: title + ' | Ivan Lesar',
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: 'og:image',
      content: metaImage,
    },
    {
      property: 'og:image:width',
      content: 1200,
    },
    {
      property: 'og:image:height',
      content: 630,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: 'og:site_name',
      content: 'Ivan Lesar',
    },
    {
      property: 'og:url',
      content: `${site?.siteMetadata?.siteUrl}${location.pathname}`,
    },
  ];

  const twitterTags = [
    {
      property: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      property: `twitter:creator`,
      content: site.siteMetadata?.social?.twitter ? '@' + site.siteMetadata?.social?.twitter : ``,
    },
    {
      property: `twitter:title`,
      content: title,
    },
    {
      property: `twitter:description`,
      content: metaDescription,
    },
    {
      property: `twitter:site`,
      content: site.siteMetadata?.social?.twitter ? '@' + site.siteMetadata?.social?.twitter : ``,
    },
    {
      property: `twitter:image`,
      content: metaImage,
    },


  ];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        ...basicTags,
        ...ogTags,
        ...twitterTags,
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
