import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Container from "./container"
import ThemeSwitch from "./themeSwitch"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          author {
            name
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  const author = data.site.siteMetadata?.author

  return (
    <div className="mt-10 border-t border-gray-200 dark:border-gray-800">
      <Container >
        <div className="flex flex-col-reverse md:flex-row items-center justify-between">
          <div className="text-sm text-center">
            Copyright © {new Date().getFullYear()} {author.name}. All
            rights reserved.
          </div>
          <ThemeSwitch />
        </div>

      </Container>
    </div>
  )
}

export default Footer
